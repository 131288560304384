<template>
  <div>
    <breadcrumb-bar
      :title="appTitle"
      :breadcrumbs="breadcrumbs"
    />
    <v-container
      fluid
    >
      <v-form>
        <base-material-card
          color="primary"
          icon="mdi-flask-plus"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <v-card-text class="display-3 font-weight-light">
              {{ appTitle }}
            </v-card-text>
          </template>
          <v-row>
            <v-col
              cols="2"
            >
              <v-text-field
                v-if="productData.ProductId != 0"
                v-model="productData.ProductId"
                dense
                class="purple-input"
                :label="this.$t('addProductLabelId')"
                readonly
              />
              <v-text-field
                v-else
                dense
                class="purple-input"
                :label="this.$t('addProductLabelId')"
                :value="this.$t('IdTextNew')"
                readonly
              />
            </v-col>
            <v-col
              cols="10"
              lg="6"
              md="6"
              sm="10"
            >
              <v-text-field
                v-model="productData.ProductName"
                :error-messages="nameErrors"
                dense
                class="purple-input"
                :label="this.$t('addProductLabelName')"
                @input="$v.productData.ProductName.$touch()"
                @blur="$v.productData.ProductName.$touch()"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="4"
              sm="12"
            >
              <v-select
                v-model="productData.ProductType"
                :items="productTypes"
                item-text="value"
                item-value="key"
                :label="this.$t('addProductLabelProductType')"
                dense
                :error-messages="productTypeErrors"
                @input="$v.productData.ProductType.$touch()"
                @blur="$v.productData.ProductType.$touch()"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="productData.Description"
                :error-messages="descriptionErrors"
                dense
                class="purple-input"
                :label="this.$t('addProductLabelDescription')"
                @input="$v.productData.Description.$touch()"
                @blur="$v.productData.Description.$touch()"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              md="6"
              sm="12"
            >
              <v-select
                v-model="productData.UnitOfMeasure"
                :items="units"
                :error-messages="unitErrors"
                item-text="name"
                item-value="unitId"
                :label="this.$t('addProductLabelUnit')"
                dense
                @input="$v.productData.UnitOfMeasure.$touch()"
                @blur="$v.productData.UnitOfMeasure.$touch()"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              md="6"
              sm="12"
            >
              <v-select
                v-model="productData.StatusCode"
                :items="statusCodes"
                :error-messages="statusCodeErrors"
                item-text="value"
                item-value="key"
                :label="this.$t('addProductLabelStatusCode')"
                dense
                @input="$v.productData.StatusCode.$touch()"
                @blur="$v.productData.StatusCode.$touch()"
              />
            </v-col>
          </v-row>
          <template v-slot:actions>
            <div>
              <v-btn
                color="success"
                :disabled="disableSubmit"
                @click="onSubmit"
              >
                {{ submitBtnText }}
              </v-btn>
              <v-btn
                text
                to="/masterdata/products"
              >
                {{ $t('buttonClose') }}
              </v-btn>
            </div>
          </template>
        </base-material-card>
      </v-form>
    </v-container>
    <error-dialog
      :dialog="showErrorDialog"
      title="Failed to Load the Application"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>

<script>
  import { mapFields } from 'vuex-map-fields'
  import { required, maxLength } from 'vuelidate/lib/validators'
  import productsAPI from '@/services/productServices'
  import i18n from '@/i18n'
  function initialState () {
    return {
      appTitle: i18n.t('titleAddProduct'),
      submitBtnText: i18n.t('buttonCreate'),
      breadcrumbs: [
        {
          text: i18n.t('bcHome'),
          disabled: false,
          href: '/dashboard',
        },
        {
          text: i18n.t('bcMasterdata'),
          disabled: true,
          href: '/masterdata',
        },
        {
          text: i18n.t('bcProducts'),
          disabled: false,
          href: '/masterdata/products',
        },
      ],
      showErrorDialog: false,
      errorMessage: '',
      modelProcessing: false,
      productData: {
        ProductId: 0,
        ProductName: '',
        Description: '',
        ProductType: '1',
        UnitOfMeasure: 0,
        StatusCode: '2',
      },
    }
  }
  export default {
    name: 'AddProduct',
    components: {
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      BreadcrumbBar: () => import('@/views/components/core/BreadcrumbBar'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
    },
    props: {
      productId: {
        type: String,
        default: '0',
      },
    },
    data: function () {
      return initialState()
    },
    validations: {
      productData: {
        ProductName: {
          required,
          maxLength: maxLength(120),
        },
        Description: {
          maxLength: maxLength(255),
        },
        ProductType: {
          required,
        },
        UnitOfMeasure: {
          required,
        },
        StatusCode: {
          required,
        },
      },
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
        productTypes: 'productTypes',
        units: 'units',
        statusCodes: 'statusCodes',
      }),
      disableSubmit: function () {
        return this.$v.$invalid
      },
      nameErrors () {
        const errors = []
        if (!this.$v.productData.ProductName.$dirty) return errors
        !this.$v.productData.ProductName.maxLength && errors.push(this.$t('addProductErrorNameMaxLen'))
        !this.$v.productData.ProductName.required && errors.push(this.$t('addProductErrorNameRequired'))
        return errors
      },
      descriptionErrors () {
        const errors = []
        if (!this.$v.productData.Description.$dirty) return errors
        !this.$v.productData.Description.maxLength && errors.push(this.$t('addProductErrorDescriptionMaxLen'))
        return errors
      },
      productTypeErrors () {
        const errors = []
        if (!this.$v.productData.ProductType.$dirty) return errors
        !this.$v.productData.ProductType.required && errors.push(this.$t('addProductErrorProductTypeRequired'))
        return errors
      },
      unitErrors () {
        const errors = []
        if (!this.$v.productData.UnitOfMeasure.$dirty) return errors
        !this.$v.productData.UnitOfMeasure.required && errors.push(this.$t('addProductErrorUnitRequired'))
        return errors
      },
      statusCodeErrors () {
        const errors = []
        if (!this.$v.productData.StatusCode.$dirty) return errors
        !this.$v.productData.StatusCode.required && errors.push(this.$t('addProductErrorStatusCodeRequired'))
        return errors
      },
    },
    created: function () {
      if (this.productId !== '0') {
        this.modelProcessing = true
        this.submitBtnText = this.$t('buttonSave')
        this.appTitle = this.$t('titleEditProduct')
        productsAPI.getProductById(this.productId)
          .then(response => {
            this.productData.Description = response.data.description
            this.productData.ProductName = response.data.productName
            this.productData.StatusCode = String(response.data.statusCode)
            this.productData.UnitOfMeasure = response.data.unitofMeasure
            this.productData.ProductId = response.data.productId
            this.productData.ProductType = String(response.data.productType)
            this.modelProcessing = false
          })
          .catch(error => {
            this.modelProcessing = false
            this.errorMessage = error.errorMessage
            this.showErrorDialog = true
          })
      }
      if (this.productTypes.length === 0) {
        this.$store.dispatch('GET_PRODUCT_TYPES')
      }
      if (this.units.length === 0) {
        this.$store.dispatch('GET_UNITS')
      }
      if (this.statusCodes.length === 0) {
        this.$store.dispatch('GET_STATUSCODES')
      }
    },
    methods: {
      onSubmit: function (event) {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.modelProcessing = true
          if (this.productData.ProductId !== 0) {
            this.productData.StatusCode = parseInt(this.productData.StatusCode)
            this.productData.ProductType = parseInt(this.productData.ProductType)
            productsAPI.updateProduct(this.productData)
              .then(response => {
                this.modelProcessing = false
                Object.assign(this.$data, initialState())
                this.$router.push('/masterdata/products')
              })
              .catch(error => {
                this.modelProcessing = false
                this.errorMessage = error.errorMessage
                this.showErrorDialog = true
              })
          } else {
            productsAPI.addProduct(this.productData)
              .then(response => {
                this.modelProcessing = false
                Object.assign(this.$data, initialState())
                this.$router.push({
                  path: '/masterdata/products',
                })
              })
              .catch(error => {
                this.showErrorDialog = true
                this.errorMessage = error.message
                this.modelProcessing = false
              })
          }
        }
      },
    },
  }
</script>
